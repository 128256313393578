import { defineStore } from 'pinia';
import { useFbFirestore } from '@/services/firebase-firestore.js';
import { safeString } from '@/services/utils.js';
import { useAuthStore } from '@/store';

const dontKnow = '?';
const breakPlease = '☕';

const state = () => ({
    userCardsets: {},
    domainCardsets: undefined,
    cardsetKey: 't_shirt_sizes',
    loadingCardsets: false,
    loadingRemoveCardset: false
});

const getters = {
    customCardsets: (state) => ({
        ...(state.userCardsets || {}),
        ...(state.domainCardsets || {})
    }),
    cardsets: (state) => ({
        ...state.defaultCardsets,
        ...(state.customCardsets || {})
    }),
    defaultCardsets: () => ({
        t_shirt_sizes: {
            key: 't_shirt_sizes',
            cards: [
                {
                    title: 'xxs',
                    value: 2
                },
                {
                    title: 'xs',
                    value: 3
                },
                {
                    title: 's',
                    value: 5
                },
                {
                    title: 'm',
                    value: 8
                },
                {
                    title: 'l',
                    value: 13
                },
                {
                    title: 'xl',
                    value: 21
                }
            ],
            isDefault: true,
            useValues: true
        }
    }),
    defaultCards: () => ({
        [dontKnow]: {
            card: {
                title: dontKnow,
                value: 0
            }
        },
        [breakPlease]: {
            card: {
                title: breakPlease,
                value: 0
                // icon: "mdi-coffee"
            }
        }
    }),
    defaultCardKeys: (state) => Object.keys(state.defaultCards),
    defaultCardsByKeys: (state) => (keys) => {
        keys.sort();
        const defaultCards = state.defaultCards;
        return keys
            .filter((key) => key in defaultCards)
            .map((key) => defaultCards[key].card);
    }
};

function buildCardsetKey(payload, user) {
    return `${safeString(payload.title)}_${user.key}`;
}

const actions = {
    async createCustomCardset(payload) {
        const authStore = useAuthStore();
        if (authStore.user?.key) {
            const key = buildCardsetKey(payload, authStore.user);
            const now = new Date();
            const newCustomCardset = {
                key,
                userKey: authStore.user.key,
                userEmail: authStore.user.email,
                domain: authStore.user.domain,
                title: payload.title,
                cards: payload.cards,
                useValues: payload.useValues,
                shared: payload.shared,
                createdAt: payload.createdAt || now,
                modifiedAt: now
            };
            const response = await useFbFirestore().set(
                ['custom-cardsets', key],
                newCustomCardset
            );
            if (response) {
                this.userCardsets[key] = newCustomCardset;
                this.cardsetKey = key;
                return newCustomCardset;
            } else {
                return response;
            }
        }
    },
    async getCustomCardsets() {
        this.loadingCardsets = true;
        const authStore = useAuthStore();
        if (this.domainCardsets === undefined && authStore.user?.key) {
            const dbService = useFbFirestore();
            const userCustomCardsets = await dbService.query(
                'custom-cardsets',
                [['userKey', '==', authStore.user.key]]
            );
            const domainCustomCardsets = await dbService.query(
                'custom-cardsets',
                [
                    ['domain', '==', authStore.user.domain],
                    ['shared', '==', true]
                ]
            );
            this.userCardsets = userCustomCardsets;
            this.domainCardsets = domainCustomCardsets;
        }
        this.loadingCardsets = false;
        return this.customCardsets;
    },
    async removeCustomCardset(payload) {
        this.loadingRemoveCardset = true;
        const authStore = useAuthStore();
        if (authStore.user?.key) {
            const key = buildCardsetKey(payload, authStore.user);
            const response = await useFbFirestore().remove([
                'custom-cardsets',
                key
            ]);
            if (response) {
                delete this.userCardsets[key];
                this.userCardsets = { ...this.userCardsets };
            }
        }
        this.loadingRemoveCardset = false;
        return this.customCardsets;
    }
};

export const useCardStore = defineStore('card', {
    state,
    getters,
    actions
});
