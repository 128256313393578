import {
    getFirestore,
    collection,
    doc,
    getDocs,
    query,
    where,
    getDoc,
    getDocFromCache,
    setDoc,
    // addDoc,
    // updateDoc,
    deleteDoc
} from 'firebase/firestore';

const FIREBASE_FIRESTORE = Object.freeze({
    createDocRef(path = [], converter) {
        const db = getFirestore();
        if (path?.constructor != Array) {
            path = [path];
        }
        const ref = converter
            ? doc(db, ...path).withConverter(converter)
            : doc(db, ...path);
        return ref;
    },
    async get(path = [], options = { cached: false }) {
        const ref = this.createDocRef(path);
        try {
            const docSnap = options?.cached
                ? await getDocFromCache(ref)
                : await getDoc(ref);
            if (docSnap.exists()) {
                return docSnap.data();
            } else {
                // console.info('No such document!');
            }
        } catch {
            // console.error(e);
        }
    },
    async set(path = [], payload) {
        const ref = this.createDocRef(path);
        try {
            await setDoc(ref, payload);
        } catch {
            // console.error(e);
        }
        return ref;
    },
    // async update(path = [], payload) {
    //     const ref = this.createDocRef(path);
    //     try {
    //         await updateDoc(ref, payload);
    //     } catch {
    //         // console.error(e);
    //     }
    //     return ref;
    // },
    createCollRef(path = '', converter) {
        const db = getFirestore();
        const ref = converter
            ? collection(db, path).withConverter(converter)
            : collection(db, path);
        return ref;
    },
    async query(path = '', wheres = [], converter) {
        const ref = this.createCollRef(path, converter);
        const q = query(ref, ...wheres.map((w) => where(...w)));
        const querySnapshot = await getDocs(q);
        const result = {};
        querySnapshot.forEach((doc) => {
            result[doc.id] = doc.data();
        });
        return result;
    },
    // async add(path = '', payload) {
    //     const ref = this.createCollRef(path);
    //     try {
    //         return await addDoc(ref, payload);
    //     } catch {
    //         // console.error(e);
    //     }
    //     return ref;
    // },
    async remove(path = []) {
        const ref = this.createDocRef(path);
        try {
            await deleteDoc(ref);
        } catch {
            // console.error(e);
        }
        return ref;
    }
    // setMulti() {},
    // removeMulti() {},
    // setOnChange() {},
    // unsetOnChange() {}
});

export const useFbFirestore = () => FIREBASE_FIRESTORE;
