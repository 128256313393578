import { useLs } from '@/services/ls.js';

export const routes = [
    {
        path: '/',
        name: 'Loading',
        component: () => import('@/views/w-loading.vue'),
        meta: {
            layout: 'centered'
        }
    },
    {
        path: '/:path(.*)',
        redirect: { name: 'Home' }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/w-home.vue'),
        meta: {
            requiresAuth: true,
            showAppBar: true,
            showPlayingCards: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/w-login.vue'),
        meta: {
            tryToAuthenticate: true,
            showPlayingCards: true
        }
    },
    {
        path: '/callback/:provider',
        name: 'Callback',
        component: () => import('@/views/w-callback.vue'),
        meta: {},
        props: (route) => ({
            provider: route.params.provider,
            code: route.query.code,
            state: route.query.state
        })
    },
    {
        path: '/order-room',
        name: 'RoomForm',
        component: () => import('@/views/w-room-form.vue'),
        meta: {
            requiresAuth: true,
            showAppBar: true,
            showPlayingCards: true,
            showOverlay: true
        }
    },
    {
        path: '/cardsets',
        name: 'CardsetList',
        component: () => import('@/views/w-cardset-list.vue'),
        meta: {
            requiresAuth: true,
            showAppBar: true,
            showPlayingCards: true,
            showOverlay: true
        }
    },
    {
        path: '/create-cardset',
        name: 'CardsetForm',
        component: () => import('@/views/w-cardset-form.vue'),
        meta: {
            requiresAuth: true,
            showAppBar: true,
            showPlayingCards: true,
            showOverlay: true
        }
    },
    {
        path: '/game/:roomCode',
        props: true,
        name: 'Game',
        component: () => import('@/views/w-game.vue'),
        meta: {
            requiresAuth: true,
            requiresValidRoom: true,
            showAppBar: true,
            fillHeight: true,
            class: 'mx-0 pt-2 px-0 view-game'
        }
    },
    {
        path: '/meet',
        name: 'MeetSidePanel',
        component: () => import('@/views/w-meet-side-panel.vue'),
        meta: {
            tryToAuthenticate: true,
            showAppBar: true
        }
    },
    {
        path: '/meet/panel/:roomCode',
        name: 'MeetMainStage',
        component: () => import('@/views/w-meet-side-panel.vue'),
        meta: {
            tryToAuthenticate: true,
            showAppBar: true
        }
    },
    {
        path: '/meet/main/:roomCode',
        name: 'MeetMainStage',
        component: () => import('@/views/w-meet-main-stage.vue'),
        meta: {
            tryToAuthenticate: true,
            showAppBar: true
        }
    }
];

function roomIsValid(roomCode) {
    try {
        let roomOrigin = window.atob(roomCode);
        let values = roomOrigin.split('-');
        let timeNow = new Date().getTime() / (1000 * 60 * 60 * 24);
        let time = new Number(values[1]) / (1000 * 60 * 60 * 24);
        if (Math.abs(timeNow - time) > 1) {
            alert('Room code has expired');
            return false;
        }
        const re =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        let email = values[0].match(re);
        if (!email) {
            alert('Invalid room code');
            return false;
        }
        return true;
    } catch {
        // console.error(e)
        alert('Invalid room code');
        return false;
    }
}

export const beforeEach = (useStore) => async (to, _) => {
    // console.log(to, from);
    if (to.params.roomCode) {
        useLs().set('roomCode', to.params.roomCode, 1000 * 60 * 60 * 24);
    }
    const store = useStore();
    if ((to.meta.requiresAuth && !store.hasUser) || to.meta.tryToAuthenticate) {
        await store.authenticate();
    }
    if (!store.hasUser && to.meta.requiresAuth && to.name != 'Login')
        return { name: 'Login' };
    if (to.meta.requiresValidRoom) {
        if (!roomIsValid(useLs().get('roomCode'))) {
            useLs().set('roomCode', '');
            return { name: 'Home' };
        }
    } else if (store.hasUser && to.name == 'Login') return { name: 'Home' };
};
