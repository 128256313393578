export function updatePlayer(user, payload) {
    let timeNow = new Date();
    let lastUpdate = {
        time: timeNow.getTime(),
        tz: timeNow.getTimezoneOffset()
    };
    user = {
        ...user,
        status: payload.status,
        label: payload.label,
        value: payload.value,
        show: false,
        lastUpdate,
        roomCode: payload.roomCode
    };
    return user;
}

function setIsMaxValue(cardsCounts) {
    const counts = Object.values(cardsCounts).map((card) => card.count);
    const maxValue = Math.max(...counts);
    for (const key in cardsCounts) {
        const card = cardsCounts[key];
        let equals = counts.filter((count) => count == card.count).length;
        cardsCounts[key].isMax = equals == 1 && card.count == maxValue;
    }
    return cardsCounts;
}

export function countCards(cards = [], players = []) {
    const cardsCounts = {};
    for (const card of cards) {
        if (card.title)
            cardsCounts[card.title] = {
                title: card.title,
                value: card.value,
                count: 0,
                players: []
            };
    }
    for (const player of players) {
        const card = cardsCounts[player.label];
        if (player.label) {
            card.count++;
            card.players.push({ name: player.name, photo: player.photo });
        }
    }
    return setIsMaxValue(cardsCounts);
}

export function findClosestLabel(cards = [], value) {
    const values = cards.map((card) => Math.abs(card.value - value));
    const min = Math.min(...values);
    const index = values.lastIndexOf(min);
    return cards[index].title;
}
