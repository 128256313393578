<template>
    <v-locale-provider>
        <v-theme-provider :theme="theme?.value || theme">
            <v-app>
                <c-app-toolbar
                    v-if="$route.meta.showAppBar"
                    :theme="theme?.value || theme"
                />
                <v-main app>
                    <c-playing-cards
                        v-if="$route.meta.showPlayingCards && animationIsOn"
                        ref="playingCards"
                        :theme="theme?.value || theme"
                    />
                    <v-overlay
                        v-if="
                            $route.meta.showOverlay &&
                            $route.meta.showPlayingCards &&
                            $refs?.playingCards?.$el
                        "
                        :model-value="true"
                        class="align-center justify-center"
                        contained
                        persistent
                        :attach="$refs?.playingCards?.$el"
                        :opacity="0.07"
                    />
                    <router-view v-slot="{ Component }">
                        <v-container
                            :class="`fill-height ${$route.meta.class || ''}`"
                            @click="$refs.playingCards?.playDeb()"
                        >
                            <v-row
                                justify="center"
                                align="center"
                                no-gutters
                                :class="
                                    $route.meta.fillHeight ? 'fill-height' : ''
                                "
                            >
                                <v-col
                                    align="center"
                                    :class="
                                        $route.meta.fillHeight
                                            ? 'fill-height'
                                            : ''
                                    "
                                >
                                    <component :is="Component" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </router-view>
                </v-main>
            </v-app>
        </v-theme-provider>
    </v-locale-provider>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useLocale, useTheme } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { useAudio } from '@/services/audio.js';
import { useMainStore } from '@/store';

import cAppToolbar from '@/components/c-app-toolbar.vue';
import cPlayingCards from '@/components/c-playing-cards.vue';
import cSelectTheme from '@/components/c-select-theme.vue';
import cSelectIconTheme from '@/components/c-select-icon-theme.vue';
import cSelectLocale from '@/components/c-select-locale.vue';

export default {
    components: {
        cAppToolbar,
        cPlayingCards,
        cSelectTheme,
        cSelectIconTheme,
        cSelectLocale
    },
    setup() {
        const { theme, animationIsOn } = storeToRefs(useMainStore());

        return {
            theme,
            animationIsOn
        };
    },
    mounted() {
        const vuetifyLocale = useLocale();
        const vuetifyTheme = useTheme();
        const audio = useAudio();

        const { locale: i18nLocale } = useI18n();

        const { theme, locale, soundStatus } = useMainStore();

        if (theme) vuetifyTheme.global.name = theme;
        if (locale) {
            vuetifyLocale.current = locale;
            i18nLocale.value = locale;
        }
        if (soundStatus) audio.playingIs = soundStatus;
    }
};
</script>

<style>
html {
    overflow-y: auto !important;
}
.elevation-1.theme--dark {
    box-shadow:
        0 2px 1px -1px rgba(100, 100, 100, 0.2),
        0 1px 1px 0 rgba(100, 100, 100, 0.14),
        0 1px 3px 0 rgba(100, 100, 100, 0.12) !important;
}
.elevation-2.theme--dark,
.v-btn--is-elevated.theme--dark {
    box-shadow:
        0 3px 1px -2px rgba(100, 100, 100, 0.2),
        0 2px 2px 0 rgba(100, 100, 100, 0.14),
        0 1px 5px 0 rgba(100, 100, 100, 0.12) !important;
}
</style>
