import common from './common.json';
import atlassian from './atlassian.json';

export default {
    common,
    atlassian,
    login: {
        signInGoogle: 'Entrar com Google',
        consent:
            'Concordo com o armazenamento e processamento dos meus dados pessoais, como nome e e-mail, meus dados de uso e dados do aplicativo, para melhorar e garantir o bom funcionamento do aplicativo.'
    },
    button: {
        signOut: 'Sair',
        newRoom: 'Nova sala',
        returnLastRoom: 'Retornar à última sala',
        showCards: 'Mostrar cartas',
        drawUser: 'Sortear usuário',
        reset: 'resetar',
        flipCards: 'Esconder cartas',
        leaveRoom: 'Sair da sala',
        addCard: 'Adicionar carta',
        removeCard: 'Remover carta',
        createCardset: `${common.create} ${common.cardset.toLowerCase()}`,
        createSubject: `${common.create} ${common.subject.toLowerCase()}`,
        importSubjects: `Importar ${common.subject.toLowerCase()}s`,
        atlassianIntegration: 'Atlassian',
        animation: {
            on: 'Com animações',
            off: 'Sem animações'
        },
        sound: {
            on: 'Com som',
            off: 'Sem som'
        },
        dudes: 'Caras'
    },
    cardset: {
        t_shirt_sizes: 'Tamanhos de fraldas (RN, XP, P, M, G, XG)'
    },
    card: {
        xxs: 'RN',
        xs: 'XP',
        s: 'P',
        m: 'M',
        l: 'G',
        xl: 'XG',
        '?': '?',
        '☕': '☕'
    },
    input: {
        useValues: 'Cartas têm valores',
        share: 'Compartilhar com meu domínio'
    },
    rule: {
        required: 'Campo obrigatório.'
    },
    text: {
        newRoom: 'Nova sala',
        newCardset: 'Novas cartas',
        cardsetForm: 'Formulário de cartas',
        myCardsets: 'Minhas cartas',
        domainCardsets: 'Cartas do domínio',
        areYouSure: 'Tem certeza?',
        selectBoard: 'Selecionar "board"',
        selectSprint: 'Selecionar "sprint"',
        youVeLeftTheRoom: 'Você saiu da sala',
        enterRoomAgain: 'Deseja entrar novamente?'
    },
    checkbox: {
        '?': 'Adicionar carta "?".',
        '☕': 'Adicionar carta "☕".'
    }
};
