<template>
    <v-select
        class="mx-3 c-select"
        color="secondary"
        density="compact"
        :variant="props.variant"
        single-line
        hide-details
        :prepend-icon="$tIcon('translate')"
        style="max-width: fit-content"
        :label="$t('common.locale')"
        :model-value="storeLocale"
        :items="Object.values(locales)"
        @update:model-value="changeLocale"
    />
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useLocale } from 'vuetify';
import { useMainStore } from '@/store';
import { useLs } from '@/services/ls.js';

const props = defineProps({
    variant: {
        type: String,
        default: () => 'outlined'
    }
});

const ls = useLs();

const vuetifyLocale = useLocale();

const { locale: i18nLocale, availableLocales } = useI18n();

const { locale: storeLocale } = storeToRefs(useMainStore());

const locales = computed(() =>
    Object.fromEntries(
        [
            { title: 'Español', value: 'es' },
            { title: 'English', value: 'en' },
            { title: 'Português', value: 'pt' }
        ]
            .filter(({ value }) => availableLocales.includes(value))
            .map((option) => [option.value, option])
    )
);

function changeLocale(value) {
    storeLocale.value = locales.value[value];
    vuetifyLocale.current = value;
    i18nLocale.value = value;
    ls.set('locale', value);
}
</script>
