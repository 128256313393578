import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { getEventUser, createDefaultId } from '@/services/utils.js';
import { useLs } from '@/services/ls.js';

const defaultId = createDefaultId(5);

const FIREBASE_ANALYTICS = Object.freeze({
    async setUserId(eventUser = {}, otherId = defaultId) {
        const ls = useLs();
        const navid = window?.navigation?.currentEntry?.id;
        let eventUserId;
        if (eventUser.uid) eventUserId = eventUser.uid;
        else eventUserId = ls.get('__uid', navid || otherId);

        const analytics = getAnalytics();
        setUserId(analytics, eventUserId);

        const user = getEventUser();
        for (let key in eventUser) {
            user[key] = eventUser[key];
        }
        user.uid = eventUserId;
        user.navid = navid;
        user.defid = otherId;
        ls.set('__uid', eventUserId);
    },
    write(eventName, eventData) {
        const analytics = getAnalytics();
        const user = getEventUser();
        return logEvent(analytics, eventName, { ...eventData, ...user });
    }
});

export const useFbAnalytics = () => FIREBASE_ANALYTICS;
