<template>
    <v-app-bar app density="compact" color="background" class="py-1">
        <v-app-bar-nav-icon @click="goHome">
            <template #default>
                <v-img
                    min-width="36"
                    max-width="36"
                    :src="
                        theme?.value || theme === 'dark'
                            ? logoWhiteSrc
                            : logoSrc
                    "
                />
            </template>
        </v-app-bar-nav-icon>
        <v-btn @click="goHome">
            <v-img
                min-width="288"
                max-width="288"
                :src="
                    theme?.value || theme === 'dark'
                        ? logoTextWhiteSrc
                        : logoTextSrc
                "
                style="cursor: pointer"
            />
        </v-btn>
        <v-spacer />
        <v-menu
            v-if="user && !loadingAuth"
            :close-on-content-click="false"
            :offset="6"
        >
            <template #activator="{ props }">
                <v-btn
                    rounded="circle"
                    v-bind="props"
                    min-width="38"
                    class="me-5 px-0"
                >
                    <v-avatar size="36">
                        <img
                            v-if="user?.photo"
                            :src="user?.photo"
                            :alt="user?.name"
                            :width="36"
                            referrerpolicy="no-referrer"
                        />
                        <v-icon
                            v-else
                            :icon="$tIcon('account_circle')"
                            dark
                            class="me-1"
                        />
                    </v-avatar>
                </v-btn>
            </template>
            <v-list lines="two">
                <v-list-item>
                    <template #default>
                        <c-select-theme variant="underlined" />
                    </template>
                </v-list-item>
                <v-list-item>
                    <template #default>
                        <c-select-icon-theme variant="underlined" />
                    </template>
                </v-list-item>
                <v-list-item>
                    <template #default>
                        <c-select-locale variant="underlined" />
                    </template>
                </v-list-item>
                <v-list-item>
                    <template #default>
                        <v-btn
                            variant="text"
                            color="on-surface"
                            class="mx-3 pa-0 c-select"
                            height="40"
                            :disabled="player?.status === 'online'"
                            @click="toggleAnimation"
                        >
                            <v-icon
                                :icon="
                                    animationIsOn
                                        ? $tIcon('eye')
                                        : $tIcon('eye-off')
                                "
                                size="24"
                                class="me-4"
                            />
                            {{ $t(`button.animation.${animationStatus}`) }}
                        </v-btn>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template #default>
                        <v-btn
                            variant="text"
                            color="on-surface"
                            class="mx-3 pa-0 c-select"
                            height="40"
                            @click="toggleSound"
                        >
                            <v-icon
                                :icon="
                                    soundIsOn
                                        ? $tIcon('volume_high')
                                        : $tIcon('volume_off')
                                "
                                size="24"
                                class="me-4"
                            />
                            {{ $t(`button.sound.${soundStatus}`) }}
                        </v-btn>
                    </template>
                </v-list-item>
                <v-list-item>
                    <template #default>
                        <v-btn
                            v-if="user && !loadingAuth"
                            variant="text"
                            color="on-surface"
                            class="mx-3 pa-0 c-select"
                            height="40"
                            @click="leave"
                        >
                            <v-icon
                                :icon="$tIcon('logout')"
                                size="24"
                                class="me-4"
                            />
                            {{ $t('button.signOut') }}
                        </v-btn>
                    </template>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { useAudio } from '@/services/audio.js';
import { useLs } from '@/services/ls.js';
import { useMainStore, useAuthStore, useGameStore } from '@/store';

import cSelectTheme from '@/components/c-select-theme.vue';
import cSelectIconTheme from '@/components/c-select-icon-theme.vue';
import cSelectLocale from '@/components/c-select-locale.vue';

import logoWhiteSrc from '@/assets/logo-white.png';
import logoTextWhiteSrc from '@/assets/logo-text-white.png';

import logoSrc from '@/assets/logo.png';
import logoTextSrc from '@/assets/logo-text.png';

defineProps({
    theme: {
        type: String,
        default: () => 'light'
    }
});

const audio = useAudio();
const ls = useLs();

const authStore = useAuthStore();
const gameStore = useGameStore();

const router = useRouter();
const route = useRoute();

const { animationStatus, soundStatus, animationIsOn, soundIsOn } =
    storeToRefs(useMainStore());

const { user, loadingAuth } = storeToRefs(authStore);

const { player } = storeToRefs(gameStore);
const { leaveRoom } = gameStore;
const { signOut } = authStore;

async function goHome() {
    if (route.name != 'Home') return await router.push({ name: 'Home' });
}
async function leave() {
    await leaveRoom();
    await signOut();
    return await router.push({ name: 'Login' });
}
function toggleAnimation() {
    animationStatus.value = animationIsOn.value ? 'off' : 'on';
    ls.set('animationStatus', animationStatus.value);
}
function toggleSound() {
    soundStatus.value = soundIsOn.value ? 'off' : 'on';
    audio.playingIs = soundStatus.value;
    if (!soundIsOn.value) audio.pause();
    ls.set('soundStatus', soundStatus.value);
}
</script>

<style scoped>
.c-select {
    opacity: var(--v-high-emphasis-opacity);
}
.c-select .v-icon {
    opacity: var(--v-medium-emphasis-opacity);
}
</style>
