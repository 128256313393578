import axios from 'axios';
import { useAtlAuth } from '@/services/atlassian-auth.js';

// docs: https://developer.atlassian.com/cloud/jira/software/rest/intro/#introduction

const ATLASSIAN = Object.freeze({
    async _jiraApiGet(
        api = '',
        params = { maxResults: 50, startAt: 0 },
        config = { repeat: true }
    ) {
        let response = {};
        let values = [];
        let total = 0;
        try {
            let tries = 0;
            let isLast = false;
            do {
                response = await axios.get(
                    `${ATLASSIAN._build_jira_api(api)}`,
                    {
                        headers: {
                            Authorization: `Bearer ${useAtlAuth().__get_access_token()}`,
                            Accept: 'application/json'
                        },
                        params
                    }
                );
                values = values.concat(
                    response.data.values || response.data.issues
                );
                if (response.data.issues) delete response.data.issues;
                isLast = response.data.isLast;
                total = response.data.total;
                params.startAt += params.maxResults;
                tries++;
            } while (
                values?.length &&
                !isLast &&
                values.length < total &&
                tries < 5
            );
        } catch (e) {
            if (e.code === 'ERR_BAD_REQUEST' && e.response.status === 401) {
                if (config.repeat) {
                    await useAtlAuth().refreshToken();
                    return await ATLASSIAN._jiraApiGet(api, params, {
                        repeat: false
                    });
                } else {
                    return useAtlAuth().signOut();
                }
            }
            // // console.error(e);
        }
        return { ...response, data: { ...response.data, values, total } };
    },
    async _jiraApiPut(
        api = '',
        params = {},
        data = {},
        config = { repeat: true }
    ) {
        let response;
        try {
            response = await axios.put(
                `${ATLASSIAN._build_jira_api(api)}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${useAtlAuth().__get_access_token()}`,
                        Accept: 'application/json'
                    },
                    params
                }
            );
        } catch (e) {
            if (e.code === 'ERR_BAD_REQUEST' && e.response.status === 401) {
                if (config.repeat) {
                    await useAtlAuth().refreshToken();
                    return await ATLASSIAN._jiraApiPut(api, params, data, {
                        repeat: false
                    });
                } else {
                    return useAtlAuth().signOut();
                }
            }
            // // console.error(e);
        }
        return response;
    },
    async getAllBoards() {
        if (useAtlAuth().isActive())
            return ATLASSIAN._jiraApiGet('rest/agile/1.0/board');
    },
    async getAllSprints(boardId) {
        if (useAtlAuth().isActive())
            return ATLASSIAN._jiraApiGet(
                `rest/agile/1.0/board/${boardId}/sprint`
            );
    },
    async getAllIssues(sprintId) {
        if (useAtlAuth().isActive())
            return ATLASSIAN._jiraApiGet(
                `rest/agile/1.0/sprint/${sprintId}/issue`,
                {
                    maxResults: 50,
                    startAt: 0,
                    fields: ATLASSIAN._issueFields().join(','),
                    jql: 'type IN standardIssueTypes()',
                    expand: 'changelog'
                }
            );
    },
    async putIssueEstimate(boardId, issueIdOrKey, data) {
        if (useAtlAuth().isActive())
            return ATLASSIAN._jiraApiPut(
                `rest/agile/1.0/issue/${issueIdOrKey}/estimation`,
                { boardId },
                data
            );
    },
    _issueFields: () => [
        'epic',
        'priority',
        'labels',
        'issuelinks',
        'assignee',
        'status',
        'creator',
        'reporter',
        'issuetype',
        'sprint',
        'customfield_10033', // Critérios de aceite
        'customfield_10026', // Story point
        'customfield_12532', // Story point
        'description',
        'attachment',
        'summary',
        'comment'
    ],
    _get_api_base_url: () => 'https://api.atlassian.com',
    _build_jira_api: (api) =>
        `${ATLASSIAN._get_api_base_url()}/ex/jira/${useAtlAuth().__get_cloudid()}/${api}` // jira or confluence
    // _build_domain_api: (api) => `${ATLASSIAN.__get_url()}/${api}`,
});

export const useAtlassian = () => ATLASSIAN;
