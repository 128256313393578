<template>
    <v-select
        class="mx-3"
        color="secondary"
        density="compact"
        :variant="props.variant"
        single-line
        hide-details
        :prepend-icon="selectIcon"
        style="max-width: fit-content"
        :label="$t('common.iconSet')"
        :model-value="iconSet"
        :items="Object.values(iconSets)"
        @update:model-value="changeIconSet"
    />
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/store';
import { useLs } from '@/services/ls.js';
import { useI18nIcons } from '@/services/i18n-icons.js';

const props = defineProps({
    variant: {
        type: String,
        default: () => 'outlined'
    }
});

const ls = useLs();

const {
    t: tIcon,
    locale: i18nIconLocale,
    availableLocales: availableLocalesIcons
} = useI18nIcons();

const { iconSet } = storeToRefs(useMainStore());

const iconSets = computed(() =>
    Object.fromEntries(
        [
            { title: 'Material', value: 'mdi', icon: tIcon('weather_sunny') },
            { title: 'FontAwesome', value: 'fa', icon: tIcon('weather_sunny') },
            { title: 'Phosphor', value: 'ph', icon: tIcon('weather_sunny') }
        ]
            .filter(({ value }) => availableLocalesIcons.includes(value))
            .map((option) => [option.value, option])
    )
);

const selectIcon = computed(
    () =>
        iconSets.value[iconSet.value?.value || iconSet.value]?.icon ||
        tIcon('weather_sunny')
);

function changeIconSet(value) {
    iconSet.value = iconSets.value[value];
    i18nIconLocale.value = value;
    ls.set('iconset', value);
}
</script>
