import { defineStore } from 'pinia';
import { useFbAuth } from '@/services/firebase-auth.js';
import { useFbFirestore } from '@/services/firebase-firestore.js';
import { useFbAnalytics } from '@/services/firebase-analytics.js';
import { useAtlAuth } from '@/services/atlassian-auth.js';
import { getEventUser, parseJwt } from '@/services/utils.js';

const state = () => ({
    user: null,
    loadingAuth: false
});

const getters = {
    hasUser: (state) => state.user?.uid,
    atlassianIsActive: () => useAtlAuth().isActive()
};

const actions = {
    redirectSignInGoogle() {
        useFbAnalytics().write('login_click', { method: 'google' });
        useFbAuth().redirectSignInGoogle();
    },
    async popupSignInGoogle() {
        useFbAnalytics().write('login_click', { method: 'google' });
        let result;
        try {
            result = await useFbAuth().popupSignInGoogle();
        } catch {
            // console.error(e);
        }
        if (result) return await this.authenticate(result);
        return;
    },
    redirectAuthAtlassian() {
        if (this.atlassianIsActive) return;
        useFbAnalytics().write('auth_click', {
            method: 'api.atlassian.com'
        });
        useAtlAuth().redirectAuthAtlassian();
    },
    async createNewUser(result, authUser) {
        const eventUser = getEventUser();
        let jwt = { hd: '' };
        if (result?.credentialsGoogle?.idToken)
            jwt = parseJwt(result.credentialsGoogle.idToken);
        const newUser = {
            uid: authUser.uid || '',
            navid: eventUser.navid || '',
            defid: eventUser.defid || '',
            name: authUser.displayName || '',
            email: authUser.email || '',
            domain: jwt.hd || '',
            photo: authUser.photoURL || '',
            providerId: result.providerId || authUser.providerId || '',
            createdAt: new Date(),
            key: authUser.email.replace(/(@|\.|#|\$|\[|\])/g, '_')
        };
        await useFbFirestore().set(['user', authUser.uid], newUser);
        return newUser;
    },
    async authenticate(result, authUser) {
        this.loadingAuth = true;
        try {
            const authService = useFbAuth();
            const eventService = useFbAnalytics();
            result = result || (await authService.getRedirectResult());
            authUser = authUser || (await authService.getCurrentUser());
            let record;
            if (authUser) {
                record = await useFbFirestore().get(['user', authUser.uid]);
            }
            if (result && record)
                useFbAnalytics().write('login', { method: result.providerId });
            else if (authUser && result && !record) {
                record = await this.createNewUser(result, authUser);
                useFbAnalytics().write('sign_up', {
                    method: result.providerId
                });
            }
            if (record) {
                eventService.setUserId(record);
                this.user = record;
                // await useAtlAuth.signOut();
            }
        } catch {
            // console.error(e);
        }
        this.loadingAuth = false;
        return this.user;
    },
    async signOut() {
        const authService = useFbAuth();
        const authUser = await authService.getCurrentUser();
        if (authUser) await authService.signOut();
        this.user = null;
    }
};

export const useAuthStore = defineStore('auth', {
    state,
    getters,
    actions
});
