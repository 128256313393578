import axios from 'axios';
import { useLs } from '@/services/ls.js';
import { getEventUser } from '@/services/utils.js';

const ATLASSIAN_AUTH = Object.freeze({
    isActive: () =>
        !!(
            ATLASSIAN_AUTH.__get_cloudid() &&
            (ATLASSIAN_AUTH.__get_access_token() ||
                ATLASSIAN_AUTH.__get_refresh_token())
        ),
    signOut() {
        ATLASSIAN_AUTH.__set_cloudid('');
        ATLASSIAN_AUTH.__set_access_token('');
        ATLASSIAN_AUTH.__set_refresh_token('');
    },
    redirectAuthAtlassian() {
        const scopes = ATLASSIAN_AUTH._get_granular_scopes();
        const user = getEventUser();
        const now = new Date();
        const state = [user.uid, now.getTime(), now.getTimezoneOffset()].join(
            '__'
        );
        const redirectUrl = ATLASSIAN_AUTH._buildOauthUrl(state, scopes);
        window?.location?.replace?.(redirectUrl);
    },
    async getAccessToken(code) {
        const response = await ATLASSIAN_AUTH._post(
            ATLASSIAN_AUTH._getTokenBaseUrl(),
            ATLASSIAN_AUTH.__build_access_token_post_data(code)
        );
        if (!response) return;
        const { data } = response;
        ATLASSIAN_AUTH.__set_access_token(data.access_token, data.expires_in);
        ATLASSIAN_AUTH.__set_refresh_token(data.refresh_token);
        await ATLASSIAN_AUTH._getCloudId();
        return response;
    },
    async refreshToken() {
        const response = await ATLASSIAN_AUTH._post(
            ATLASSIAN_AUTH._getTokenBaseUrl(),
            ATLASSIAN_AUTH.__build_refresh_token_post_data()
        );
        if (!response) return;
        const { data } = response;
        ATLASSIAN_AUTH.__set_access_token(data.access_token, data.expires_in);
        ATLASSIAN_AUTH.__set_refresh_token(data.refresh_token);
        await ATLASSIAN_AUTH._getCloudId();
        return response;
    },
    async getMe() {
        return await ATLASSIAN_AUTH._get(
            `${ATLASSIAN_AUTH._getApiBaseUrl()}/me`
        );
    },
    async _getCloudId() {
        const url = `${ATLASSIAN_AUTH._getApiBaseUrl()}/oauth/token/accessible-resources`;
        const response = await ATLASSIAN_AUTH._get(url);
        if (!response) return;
        for (const data of response.data) {
            ATLASSIAN_AUTH.__set_cloudid(data.id); // data has id, url and some domain info
            break;
        }
    },
    async _post(url, payload) {
        let response;
        try {
            response = await axios.post(url, payload, {
                headers: { 'Content-Type': 'application/json' }
            });
        } catch {
            // console.error(e);
        }
        return response;
    },
    async _get(url) {
        let response;
        try {
            response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${ATLASSIAN_AUTH.__get_access_token()}`,
                    Accept: 'application/json'
                }
            });
        } catch (e) {
            // // console.error(e);
            if (e.response.data?.code == 401) {
                // try to refresh token
            }
        }
        return response;
    },
    __build_access_token_post_data: (code = '') => ({
        grant_type: 'authorization_code',
        client_id: ATLASSIAN_AUTH.__get_client_id(),
        client_secret: ATLASSIAN_AUTH.__get_client_secret(),
        code,
        redirect_uri: ATLASSIAN_AUTH._getRedirectUrl()
    }),
    __build_refresh_token_post_data: () => ({
        grant_type: 'refresh_token',
        client_id: ATLASSIAN_AUTH.__get_client_id(),
        client_secret: ATLASSIAN_AUTH.__get_client_secret(),
        refresh_token: ATLASSIAN_AUTH.__get_refresh_token()
    }),
    _getApiBaseUrl: () => 'https://api.atlassian.com',
    _getAuthBaseUrl: () => 'https://auth.atlassian.com',
    _getTokenBaseUrl: () => `${ATLASSIAN_AUTH._getAuthBaseUrl()}/oauth/token`,
    _getOauthBaseUrl: () => `${ATLASSIAN_AUTH._getAuthBaseUrl()}/authorize`,
    _getAppBaseUrl: () => import.meta.env.VITE_BASE_URL,
    _getRedirectUrl: () =>
        `${ATLASSIAN_AUTH._getAppBaseUrl()}/callback/atlassian`,
    _buildOAuthParams: (state = '', scopes = []) => ({
        audience: 'api.atlassian.com',
        client_id: ATLASSIAN_AUTH.__get_client_id(),
        scope: scopes.join(' '),
        redirect_uri: ATLASSIAN_AUTH._getRedirectUrl(),
        state,
        response_type: 'code',
        prompt: 'consent'
    }),
    _buildOauthUrl: (state = '', scopes = []) =>
        new URL(
            `?${new URLSearchParams(ATLASSIAN_AUTH._buildOAuthParams(state, scopes))}`,
            ATLASSIAN_AUTH._getOauthBaseUrl()
        ),
    __get_client_id: () => import.meta.env.VITE_ATLASSIAN_CLIENT_ID,
    __get_client_secret: () => import.meta.env.VITE_ATLASSIAN_SECRET,
    // _get_id_scopes: () => ['read:me'],
    // _get_classic_scopes: () => [
    //     'read:jira-user',
    //     'read:jira-work',
    //     'write:jira-work'
    // ],
    _get_granular_scopes: () => [
        'offline_access',
        'read:me',
        'read:project:jira',
        'read:board-scope:jira-software',
        'read:sprint:jira-software',
        'read:issue-details:jira',
        'read:jql:jira',
        'write:issue:jira-software'
    ],
    __get_access_token: () => useLs().get('__atlassian_access_token', ''),
    __set_access_token: (value, expireIn) =>
        useLs().set('__atlassian_access_token', value, expireIn * 60 * 1000),
    __get_refresh_token: () => useLs().get('__atlassian_refresh_token', ''),
    __set_refresh_token: (value, expireIn) =>
        useLs().set('__atlassian_refresh_token', value, expireIn),
    __get_cloudid: () => useLs().get('__atlassian_cloudid', ''),
    __set_cloudid: (value, expireIn) =>
        useLs().set('__atlassian_cloudid', value, expireIn)
});

export const useAtlAuth = () => ATLASSIAN_AUTH;
