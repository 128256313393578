import common from './common.json';
import atlassian from './atlassian.json';

export default {
    common,
    atlassian,
    login: {
        signInGoogle: 'Entrar con Google',
        consent:
            'Acepto el almacenamiento y procesamiento de mis datos personales, como nombre y correo electrónico, mis datos de uso y los de la aplicación, para mejorar y garantizar el buen funcionamiento de la aplicación.'
    },
    button: {
        signOut: 'Salir',
        newRoom: 'Nueva sala',
        returnLastRoom: 'Volver a última sala',
        showCards: 'Mostrar cartas',
        drawUser: 'Sortear usuário',
        reset: 'Resetar',
        flipCards: 'Voltear cartas',
        leaveRoom: 'Dejar sala',
        addCard: 'Agregar carta',
        removeCard: 'Remover carta',
        createCardset: `${common.create} ${common.cardset.toLowerCase()}`,
        createSubject: `${common.create} ${common.subject.toLowerCase()}`,
        importSubjects: `Importar ${common.subject.toLowerCase()}s`,
        atlassianIntegration: 'Atlassian',
        animation: {
            on: 'Con animaciones',
            off: 'Sin animaciones'
        },
        sound: {
            on: 'Con sonido',
            off: 'Sin sonido'
        },
        dudes: 'Tipos'
    },
    cardset: {
        t_shirt_sizes: 'Tamaños de pañales (RN, XP, P, M, G, XG)'
    },
    card: {
        xxs: 'RN',
        xs: 'XP',
        s: 'P',
        m: 'M',
        l: 'G',
        xl: 'XG',
        '?': '?',
        '☕': '☕'
    },
    input: {
        useValues: 'Cartas tienen valores',
        share: 'Compartir con mi dominio'
    },
    rule: {
        required: 'Campo obligatorio.'
    },
    text: {
        newRoom: 'Nueva sala',
        newCardset: 'Nuevas cartas',
        cardsetForm: 'Formulario de cartas',
        myCardsets: 'Mis cartas',
        domainCardsets: 'Cartas del dominio',
        areYouSure: 'Estas seguro?',
        selectBoard: 'Eligir "board"',
        selectSprint: 'Eligir "sprint"',
        youVeLeftTheRoom: 'Has dejado la sala',
        enterRoomAgain: 'Quieres entrar nuevamente?'
    },
    checkbox: {
        '?': 'Agregar carta "?".',
        '☕': 'Agregar carta "☕".'
    }
};
