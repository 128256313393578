import { createI18n } from 'vue-i18n';

let global = {};

const I18NICONS = Object.freeze({
    get global() {
        return global;
    },
    set global(value) {
        global = value || {};
    }
});

export function createI18nIcons(options = {}) {
    const i18nIcons = createI18n({
        ...options
    });

    I18NICONS.global = i18nIcons.global;
}

export function useI18nIcons() {
    return I18NICONS.global;
}

export const i18nIconsPlugin = {
    // app, options
    install: (app, _) => {
        const { t: tIcon } = useI18nIcons();
        app.config.globalProperties.$tIcon = tIcon;
    }
};
