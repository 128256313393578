<template>
    <v-select
        class="mx-3"
        color="secondary"
        density="compact"
        :variant="props.variant"
        single-line
        hide-details
        :prepend-icon="themeIconKey"
        style="max-width: fit-content"
        :label="$t('common.theme')"
        :model-value="theme"
        :items="Object.values(themes)"
        @update:model-value="changeTheme"
    />
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';
import { useMainStore } from '@/store';
import { useLs } from '@/services/ls.js';
import { useI18nIcons } from '@/services/i18n-icons.js';

const props = defineProps({
    variant: {
        type: String,
        default: () => 'outlined'
    }
});

const ls = useLs();

const vuetifyTheme = useTheme();

const { t } = useI18n();

const { t: tIcon } = useI18nIcons();

const { theme } = storeToRefs(useMainStore());

const themes = computed(() =>
    Object.fromEntries(
        [
            {
                title: t('common.light'),
                value: 'light',
                icon: tIcon('weather_sunny')
            },
            {
                title: t('common.dark'),
                value: 'dark',
                icon: tIcon('weather_night')
            }
        ].map((option) => [option.value, option])
    )
);

const themeIconKey = computed(
    () =>
        themes.value[theme.value?.value || theme.value]?.icon ||
        tIcon('weather_sunny')
);

function changeTheme(value) {
    theme.value = themes.value[value];
    vuetifyTheme.name = value;
    ls.set('theme', value);
}
</script>
