import { insertFavicon } from '@/services/utils.js';
import { initializeFirebase } from '@/services/firebase.js';
import startClarity from '@/plugins/clarity';

insertFavicon();
initializeFirebase();

if (import.meta.env.VITE_AUTH_DOMAIN == 'dev.conectaplanning.com.br')
    startClarity();

if (
    import.meta.env.VITE_BASE_URL === 'https://dev.conectaplanning.com.br' &&
    window?.location?.host &&
    window.location.host !== 'dev.conectaplanning.com.br'
)
    window.location.replace('https://dev.conectaplanning.com.br');

import { useLs, lsPlugin } from '@/services/ls.js';

import { createApp } from 'vue';

import { createI18n } from 'vue-i18n';
import vueI18nMessages from '@/locales';

import { createVuetify } from 'vuetify';
import vuetifyConfig from '@/plugins/vuetify.js';

import { createPinia } from 'pinia';
import { useAuthStore } from '@/store';

import { createRouter, createWebHistory } from 'vue-router';
import { routes, beforeEach } from '@/router';

import { createI18nIcons, i18nIconsPlugin } from '@/services/i18n-icons';
import vueI18nMessagesIcons from '@/icons';

const dev = import.meta.env.MODE !== 'production';

const ls = useLs();

const defaultLocale = 'en';
const locale = ls.get('locale', defaultLocale);

const defaultIconSet = 'ph';
const iconSet = ls.get('iconset', defaultLocale);

const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: defaultLocale,
    messages: vueI18nMessages,
    fallbackWarn: dev,
    missingWarn: dev
});

createI18nIcons({
    legacy: false,
    locale: iconSet,
    fallbackLocale: defaultIconSet,
    messages: vueI18nMessagesIcons,
    fallbackWarn: dev,
    missingWarn: dev
});

const vuetify = createVuetify(vuetifyConfig(i18n));

const pinia = createPinia();

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, behavior: 'smooth' };
    }
});
router.beforeEach(beforeEach(useAuthStore));
// router.onError((e) => console.log(e));

import App from '@/w-app.vue';

export default createApp(App)
    .use(lsPlugin)
    .use(i18nIconsPlugin)
    .use(i18n)
    .use(vuetify)
    .use(pinia)
    .use(router)
    .mount('#app');
