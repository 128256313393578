import { getDatabase, get, set, ref, onValue, off } from 'firebase/database';

const FIREBASE_REALTIME_DATABASE = Object.freeze({
    createRef(path = '') {
        const db = getDatabase();
        return ref(db, path);
    },
    async get(path = '') {
        try {
            const snapshot = await get(this.createRef(path));
            if (snapshot.exists()) {
                return snapshot.val();
            }
        } catch {
            // console.error(e);
        }
    },
    set(path = '', payload) {
        set(this.createRef(path), payload);
    },
    remove(path = '') {
        set(this.createRef(path), null);
    },
    setOnChange(path = '', handler) {
        onValue(this.createRef(path), handler);
    },
    unsetOnChange(path = '') {
        off(this.createRef(path));
    }
    // update() {}, // is a set with key as a document
    // query() {},
    // add() {}, // is a set without key in a collection
    // setMulti() {},
    // removeMulti() {}
});

export const useFbRealtime = () => FIREBASE_REALTIME_DATABASE;
