import common from './common.json';
import atlassian from './atlassian.json';

export default {
    common,
    atlassian,
    login: {
        signInGoogle: 'Sign in with Google',
        consent:
            'I consent to the storage and processing of my personal data, like name and e-mail, my usage and application data, for improvement and proper functionality of the app.'
    },
    button: {
        signOut: 'Sign out',
        newRoom: 'New room',
        returnLastRoom: 'Return to last room',
        showCards: 'Show cards',
        drawUser: 'Sort user',
        reset: 'Reset',
        flipCards: 'Flip cards',
        leaveRoom: 'Leave room',
        addCard: 'Add card',
        removeCard: 'Remove card',
        createCardset: `${common.create} ${common.cardset.toLowerCase()}`,
        createSubject: `${common.create} ${common.subject.toLowerCase()}`,
        importSubjects: `Import ${common.subject.toLowerCase()}s`,
        atlassianIntegration: 'Atlassian',
        animation: {
            on: 'Animation on',
            off: 'Animation off'
        },
        sound: {
            on: 'Sound on',
            off: 'Sound off'
        },
        dudes: 'Dudes'
    },
    cardset: {
        t_shirt_sizes: 'T-shirt sizes (XXS, XS, S, M, L, XL)'
    },
    card: {
        xxs: 'XXS',
        xs: 'XS',
        s: 'S',
        m: 'M',
        l: 'L',
        xl: 'XL',
        '?': '?',
        '☕': '☕'
    },
    input: {
        useValues: 'Use values',
        share: 'Share in my domain'
    },
    rule: {
        required: 'Required field.'
    },
    text: {
        newRoom: 'New room',
        newCardset: 'New cardset',
        cardsetForm: 'Cardset form',
        myCardsets: 'My cardsets',
        domainCardsets: 'Domain cardsets',
        areYouSure: 'Are you sure?',
        selectBoard: 'Select board',
        selectSprint: 'Select sprint',
        youVeLeftTheRoom: "You've left the room",
        enterRoomAgain: 'Do you want to enter again?'
    },
    checkbox: {
        '?': 'Add "?" card.',
        '☕': 'Add "☕" card.'
    }
};
