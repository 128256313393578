import { defineStore } from 'pinia';
import { useFbRealtime } from '@/services/firebase-realtime.js';
import { useFbAnalytics } from '@/services/firebase-analytics.js';
import {
    updatePlayer,
    countCards,
    findClosestLabel
} from '@/controllers/player.js';
import { useLs } from '@/services/ls.js';
import { useAtlassian } from '@/services/atlassian-jira.js';
import { useAuthStore } from '@/store/auth.js';

const ls = useLs();

const state = () => ({
    player: null,
    players: [],
    playerForTic: null,
    lastPlayerForTic: null,
    cards: null,
    isDefault: null,
    useValues: null,
    boardId: null,
    sprintId: null,
    subjects: []
});

const getters = {
    // gamesResults: () => ({
    //     mode(arr = []) {
    //         const counted = arr.reduce((acc, curr) => {
    //             acc[curr] = (acc[curr] ?? 0) + 1;
    //             return acc;
    //         }, {});
    //         const maxCount = Math.max(...Object.values(counted));
    //         const results = Object.keys(counted).filter(
    //             (key) => counted[key] === maxCount
    //         );
    //         return { counted, maxCount, results };
    //     },
    //     mean(arr = []) {
    //         const sum = arr.reduce((acc, curr) => acc + curr);
    //         return sum / arr.length;
    //     }
    // }),
    playersOnline: (state) =>
        state.players
            .filter((player) => player.status == 'online')
            .sort(
                (a, b) =>
                    !!b.label - !!a.label ||
                    (b.email == state.playerForTic) -
                        (a.email == state.playerForTic)
            ),
    playersOffline: (state) =>
        state.players.filter((player) => player?.status != 'online'),
    showing: (state) =>
        state.playersOnline.length > 0 &&
        state.playersOnline.every((player) => player.show),
    cardsCounts: (state) =>
        !state.showing ? {} : countCards(state.cards, state.playersOnline),
    meanCard: (state) => {
        const playersWithValue = state.playersOnline.filter(
            (player) => player.value
        );
        const total = [0, ...playersWithValue].reduce(
            (total, next) => total + next.value
        );
        const mean =
            Math.round((total / playersWithValue.length || 0) * 10) / 10;
        return {
            title: findClosestLabel(state.cards, mean),
            value: mean
        };
    }
};

const actions = {
    async setPlayer(payload) {
        const { roomCode } = payload;
        if (!roomCode) return;
        const authStore = useAuthStore();
        const player = updatePlayer(authStore.user, payload);
        useFbRealtime().set(`state/${roomCode}/players/${player.key}`, player);
        this.player = player;
        return player;
    },
    setPlayers({ roomCode, show }) {
        if (!roomCode) return;
        const thisUserKey = this.player.key;
        const timeNow = new Date();
        const statePlayers = this.players.reduce((total, player) => {
            const lastUpdateTime = player.lastUpdate?.time || 0;
            const diff = (timeNow - lastUpdateTime) / (1000 * 60);
            let logoff = false;
            if (diff > 10 && player.key != thisUserKey && !player.label) {
                logoff = true;
            } else if (!player.label && player.status == 'offline') {
                logoff = true;
            }
            total[player.key] = {
                ...player,
                show: logoff ? logoff : show,
                label: !show ? '' : player.label,
                value: !show ? '' : player.value,
                status: logoff ? 'offline' : 'online'
            };
            return total;
        }, {});
        useFbRealtime().set(`state/${roomCode}/players`, statePlayers);
        return statePlayers;
    },
    async setIssues({ roomCode, issues }) {
        if (!roomCode) return;

        const subjects = Object.fromEntries(
            issues
                .sort((a, b) => b.id - a.id)
                .filter(
                    (issue) => !issue.fields.issuetype.subtask
                    // !issue.fields.issuetype.subtask &&
                    // !issue.fields.customfield_10026
                )
                .map((issue) => {
                    const key = issue.key.replace(/(@|\.|#|\$|\[|\])/g, '_');
                    return [
                        key,
                        {
                            _key: key,
                            key: issue.key,
                            id: issue.id,
                            ...Object.fromEntries(
                                useAtlassian()
                                    ._issueFields()
                                    .map((key) => [
                                        key,
                                        issue.fields[key] || ''
                                    ])
                            )
                        }
                    ];
                })
        );
        useFbRealtime().set(`state/${roomCode}/subjects`, subjects);

        return subjects;
    },
    async removeSubject({ roomCode, subject }) {
        if (!roomCode) return;
        this.subjects.splice(
            this.subjects.find((value) => value.key == subject.key),
            1
        );
        useFbRealtime().remove(`state/${roomCode}/subjects/${subject.key}`);
        return this.subjects;
    },
    dbStateChangeHandler(payload) {
        const data = payload.val() || {
            creatorKey: '',
            playerForTic: '',
            lastPlayerForTic: '',
            players: {},
            cardset: {},
            subjects: {}
        };
        this.subjects = Object.values(data.subjects || {});
        this.players = Object.values(data.players || {});
        this.playerForTic = data.playerForTic || '';
        this.lastPlayerForTic = data.lastPlayerForTic || '';

        this.player = { ...this.player, ...data.players[this.player.key] };

        const { cards, isDefault, useValues } = data.cardset;
        if (this.cards != cards) this.cards = Object.values(cards);
        if (this.isDefault != isDefault) this.isDefault = isDefault;
        if (this.useValues != useValues) this.useValues = useValues;

        return;
    },
    async createRoom(payload) {
        const authStore = useAuthStore();
        const player = updatePlayer(authStore.user, {
            status: 'online',
            label: '',
            value: 0,
            show: false,
            creator: true
        });
        player.roomCode = window.btoa(
            `${player.email}-${player.lastUpdate.time}`
        );
        this.player = player;
        useFbRealtime().set(`state/${player.roomCode}`, {
            creatorKey: player.key,
            players: { [player.key]: player },
            cardset: payload.cardset,
            subjects: []
        });
        useFbAnalytics().write('create_room', { player, payload });
        return player.roomCode;
    },
    async enterRoom(roomCode) {
        useFbRealtime().setOnChange(
            `state/${roomCode}`,
            this.dbStateChangeHandler
        );
        const expireIn = 1000 * 60 * 60 * 24;
        ls.set('roomCode', roomCode, expireIn);
        if (!window.onbeforeunload) window.onbeforeunload = this.leaveRoom;
        if (!window.onunload) window.onunload = this.leaveRoom;
    },
    async leaveRoom() {
        const roomCode = this.player?.roomCode;
        if (!roomCode) return;
        await this.setPlayer({
            status: 'offline',
            label: '',
            value: 0,
            show: false,
            roomCode
        });
        useFbRealtime().unsetOnChange(`state/${roomCode}`);
        window.onbeforeunload = null;
        window.onunload = null;
        return roomCode;
    },
    setSortedPlayerForTic({ roomCode, email }) {
        if (!roomCode) return;
        useFbRealtime().set(`state/${roomCode}/playerForTic`, email);
        this.playerForTic = email;
        if (email) {
            this.lastPlayerForTic = email;
            useFbRealtime().set(`state/${roomCode}/lastPlayerForTic`, email);
        }
        return email;
    }
};

export const useGameStore = defineStore('game', {
    state,
    getters,
    actions
});
