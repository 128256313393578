import {
    getAuth,
    signInWithRedirect,
    getRedirectResult,
    signInWithPopup,
    GoogleAuthProvider,
    // OAuthProvider,
    signOut
} from 'firebase/auth';

const FIREBASE_AUTH = Object.freeze({
    redirectSignInGoogle() {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account',
            access_type: 'offline'
        });
        const scopes = [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
        ];
        for (let scope of scopes) {
            provider.addScope(scope);
        }
        signInWithRedirect(auth, provider);
    },
    async popupSignInGoogle() {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account',
            access_type: 'offline'
        });
        const scopes = [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
        ];
        for (let scope of scopes) {
            provider.addScope(scope);
        }
        try {
            const result = await signInWithPopup(auth, provider);
            return {
                ...result,
                credentialsGoogle:
                    GoogleAuthProvider.credentialFromResult(result)
            };
        } catch {
            return;
        }
    },
    async getRedirectResult() {
        const auth = getAuth();
        const result = await getRedirectResult(auth);
        if (!result) return;
        return {
            ...result,
            credentialsGoogle: GoogleAuthProvider.credentialFromResult(result)
            // credentialsAtlassian: OAuthProvider.credentialFromResult(result)
        };
    },
    async getCurrentUser() {
        const auth = getAuth();
        if (!auth.currentUser) await getRedirectResult(auth);
        return auth.currentUser;
    },
    async signOut() {
        const auth = getAuth();
        return await signOut(auth);
    }
});

export const useFbAuth = () => FIREBASE_AUTH;
