let storage;
try {
    storage = window.localStorage;
} catch {
    storage = {
        getItem: (key) => storage[key],
        setItem: (key, value) => (storage[key] = value),
        removeItem: (key) => delete storage[key]
    };
}

const LS = Object.freeze({
    set(key, value, expireIn = null) {
        storage.setItem(
            key,
            JSON.stringify({
                timestamp: new Date().getTime(),
                value,
                expireIn
            })
        );
    },
    get(key, value) {
        try {
            const got = JSON.parse(storage.getItem(key));
            if (got.expireIn) {
                const now = new Date().getTime();
                if (now - got.timestamp > got.expireIn) {
                    storage.removeItem(key);
                    return value;
                }
            }
            return got.value || value;
        } catch {
            // console.error(e)
            storage.removeItem(key);
            return value;
        }
    }
});

export const useLs = () => LS;

export const lsPlugin = {
    // app, options
    install: (app, _) => {
        app.config.globalProperties.$ls = useLs();
    }
};
